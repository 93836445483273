<template>
  <div class="skeleton-container">
    <!-- 游戏详情页骨架屏 -->
    <template v-if="type === 'game-detail'">
      <div class="game-detail-skeleton">
        <!-- 标题骨架 -->
        <el-skeleton :rows="1" animated>
          <template slot="template">
            <el-skeleton-item variant="h1" style="width: 50%; margin: 0 auto 20px;" />
            <el-skeleton-item variant="text" style="width: 30%; margin: 0 auto;" />
          </template>
        </el-skeleton>

        <!-- 轮播图骨架 -->
        <el-skeleton animated style="margin-bottom: 30px;">
          <template slot="template">
            <el-skeleton-item variant="image" style="height: 420px;" />
          </template>
        </el-skeleton>

        <el-row :gutter="20">
          <!-- 左侧内容骨架 -->
          <el-col :span="19">
            <el-skeleton animated :rows="6">
              <template slot="template">
                <el-skeleton-item variant="image" style="height: 300px; margin-bottom: 20px;" />
                <el-skeleton-item variant="p" style="width: 100%" />
                <el-skeleton-item variant="p" style="width: 80%" />
                <el-skeleton-item variant="p" style="width: 60%" />
                <el-skeleton-item variant="p" style="width: 40%" />
                <el-skeleton-item variant="p" style="width: 20%" />
              </template>
            </el-skeleton>
          </el-col>

          <!-- 右侧内容骨架 -->
          <el-col :span="5">
            <el-skeleton animated :rows="4">
              <template slot="template">
                <el-skeleton-item variant="image" style="height: 200px; margin-bottom: 20px;" />
                <el-skeleton-item variant="p" style="width: 100%" />
                <el-skeleton-item variant="p" style="width: 80%" />
                <el-skeleton-item variant="p" style="width: 60%" />
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
    </template>

    <!-- 首页骨架屏 -->
    <template v-if="type === 'home'">
      <div class="home-skeleton">
        <!-- 轮播图骨架 -->
        <el-skeleton animated style="margin-bottom: 30px;">
          <template slot="template">
            <el-skeleton-item variant="image" style="height: 400px;" />
          </template>
        </el-skeleton>

        <!-- 内容区域骨架 -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-skeleton animated :rows="4">
              <template slot="template">
                <el-skeleton-item variant="image" style="height: 200px; margin-bottom: 20px;" />
                <el-skeleton-item variant="p" style="width: 100%" />
                <el-skeleton-item variant="p" style="width: 80%" />
                <el-skeleton-item variant="p" style="width: 60%" />
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
    </template>

    <!-- 文章列表页骨架屏 -->
    <template v-if="type === 'article-list'">
      <div class="article-list-skeleton">
        <el-skeleton animated :rows="6">
          <template slot="template">
            <el-skeleton-item variant="h1" style="width: 50%; margin-bottom: 20px;" />
            <el-skeleton-item variant="text" style="width: 100%; margin-bottom: 20px;" />
            <el-skeleton-item variant="image" style="height: 200px; margin-bottom: 20px;" />
            <el-skeleton-item variant="p" style="width: 100%" />
            <el-skeleton-item variant="p" style="width: 80%" />
            <el-skeleton-item variant="p" style="width: 60%" />
          </template>
        </el-skeleton>
      </div>
    </template>

    <!-- 文章详情页骨架屏 -->
    <template v-if="type === 'article-detail'">
      <div class="article-detail-skeleton">
        <el-skeleton animated :rows="8">
          <template slot="template">
            <el-skeleton-item variant="h1" style="width: 70%; margin-bottom: 20px;" />
            <el-skeleton-item variant="text" style="width: 40%; margin-bottom: 20px;" />
            <el-skeleton-item variant="image" style="height: 300px; margin-bottom: 20px;" />
            <el-skeleton-item variant="p" style="width: 100%" />
            <el-skeleton-item variant="p" style="width: 90%" />
            <el-skeleton-item variant="p" style="width: 80%" />
            <el-skeleton-item variant="p" style="width: 70%" />
            <el-skeleton-item variant="p" style="width: 60%" />
          </template>
        </el-skeleton>
      </div>
    </template>

    <!-- 用户中心骨架屏 -->
    <template v-if="type === 'user-center'">
      <div class="user-center-skeleton">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-skeleton animated :rows="4">
              <template slot="template">
                <el-skeleton-item variant="image" style="height: 150px; margin-bottom: 20px;" />
                <el-skeleton-item variant="p" style="width: 100%" />
                <el-skeleton-item variant="p" style="width: 80%" />
                <el-skeleton-item variant="p" style="width: 60%" />
              </template>
            </el-skeleton>
          </el-col>
          <el-col :span="18">
            <el-skeleton animated :rows="6">
              <template slot="template">
                <el-skeleton-item variant="h1" style="width: 50%; margin-bottom: 20px;" />
                <el-skeleton-item variant="p" style="width: 100%" />
                <el-skeleton-item variant="p" style="width: 80%" />
                <el-skeleton-item variant="p" style="width: 60%" />
                <el-skeleton-item variant="p" style="width: 40%" />
                <el-skeleton-item variant="p" style="width: 20%" />
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
    </template>

    <!-- 充值页面骨架屏 -->
    <template v-if="type === 'recharge'">
      <div class="recharge-skeleton">
        <el-skeleton animated :rows="4">
          <template slot="template">
            <el-skeleton-item variant="h1" style="width: 40%; margin-bottom: 20px;" />
            <el-skeleton-item variant="image" style="height: 200px; margin-bottom: 20px;" />
            <el-skeleton-item variant="p" style="width: 100%" />
            <el-skeleton-item variant="p" style="width: 80%" />
          </template>
        </el-skeleton>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    type: {
      type: String,
      required: true,
      validator: value => ['game-detail', 'home', 'article-list', 'article-detail', 'user-center', 'recharge','psgamedetail'].includes(value)
    }
  }
}
</script>

<style scoped>
.skeleton-container {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.game-detail-skeleton,
.home-skeleton,
.article-list-skeleton,
.article-detail-skeleton,
.user-center-skeleton,
.recharge-skeleton {
  max-width: 1400px;
  margin: 0 auto;
}
</style> 