<template>
  <div class="game-detail-container">
    <skeleton v-if="loading" type="game-detail" />
    <template v-else>
      <!-- 游戏标题 -->
      <div class="game-header">
        <h1 class="game-title">{{ this.form.name }}</h1>
        <div class="game-meta">
          <el-rate
            v-model="value5"
            disabled
            show-score
            text-color="#ff9900"
            score-template="{value}"
          ></el-rate>
          <span class="review-count">评价数：{{ form.recentreviews }}</span>
        </div>
      </div>
      <!-- 游戏轮播图 -->
      <div class="game-carousel">
        <el-carousel :interval="4000" type="card" height="420px">
          <el-carousel-item v-for="item in this.form.detialimg" :key="item">
            <img :src="item" class="carousel-image"/>
          </el-carousel-item>
        </el-carousel>
      </div>

      <el-row :gutter="20" class="main-content" style="margin: 0 auto">
        <!-- 左侧主要内容 -->
        <el-col :span="19">
          <div class="content-section">
            <!-- 游戏基本信息 -->
            <el-card class="game-info-card" shadow="hover">
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="game-description">
                    <h2>游戏简介</h2>
                    <p>{{ form.description }}</p>
                    <div class="game-tags">
                      <el-tag type="success" size="medium">游戏ID：{{ form.gameid }}</el-tag>
                      <el-tag type="warning" size="medium">发行日期：{{ form.releasedate }}</el-tag>
                      <el-tag type="info" size="medium">开发商：{{ form.developers }}</el-tag>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="game-cover">
                    <img :src="form.imgsrc" class="cover-image"/>
                  </div>
                </el-col>
              </el-row>
            </el-card>

            <!-- 游戏视频 -->
            <el-card class="game-video-card" shadow="hover">
              <div slot="header" class="card-header">
                <span><i class="el-icon-video-camera"></i> 游戏视频</span>
              </div>
              <el-carousel indicator-position="outside" :autoplay="false" trigger="click" height="650px">
                <el-carousel-item v-for="item in this.form.video1" :key="item">
                  <video :src="item" controls="controls" class="video-player"></video>
                </el-carousel-item>
              </el-carousel>
            </el-card>

            <!-- 游戏详情 -->
            <el-card class="game-detail-card" shadow="hover">
              <div slot="header" class="card-header">
                <span><i class="el-icon-info"></i> 游戏详情</span>
              </div>
              <div class="detail-content">
                <div :class="['detail-text', {'detail-text-collapsed': !isExpanded}]" ref="detailContent">
                  <div v-html="form.concerning"></div>
                </div>
                <div class="detail-expand" v-if="hasOverflow">
                  <el-button 
                    type="text" 
                    @click="toggleExpand"
                    class="expand-btn">
                    {{ isExpanded ? '收起' : '展开全部' }}
                    <i :class="['el-icon-arrow-' + (isExpanded ? 'up' : 'down')]"></i>
                  </el-button>
                </div>
              </div>
            </el-card>

            <!-- Steam商店组件 -->
            <div class="steam-widget">
              <iframe :src="'https://store.steampowered.com/widget/'+form.gameid+'/?cc=CN'" 
                      frameborder="0" 
                      class="steam-iframe">
              </iframe>
            </div>

            <!-- 游戏评价 -->
            <el-card class="game-review-card" shadow="hover">
              <div slot="header" class="card-header">
                <span><i class="el-icon-chat-dot-round"></i> 游戏评价</span>
              </div>
              <div class="review-list">
                <el-row :gutter="20">
                  <el-col :span="12" v-for="(item, index) of comments" :key="index">
                    <el-card class="review-item" shadow="hover">
                      <div class="review-content">
                        <div class="review-avatar">
                          <el-avatar :size="50" :src="'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'">
                            <i class="el-icon-user-solid"></i>
                          </el-avatar>
                        </div>
                        <div class="review-text">
                          <div class="review-header">
                            <span class="reviewer-name">玩家 {{ index + 1 }}</span>
                            <el-rate
                              v-model="value5"
                              disabled
                              show-score
                              text-color="#ff9900"
                              score-template="{value}"
                              size="small"
                            ></el-rate>
                          </div>
                          <p class="review-content-text">{{ item }}</p>
                          <div class="review-footer">
                            <span class="review-time">{{ new Date().toLocaleDateString() }}</span>
                            <el-button type="text" size="small" class="like-btn">
                              <i class="el-icon-star-off"></i>
                              <span>有帮助</span>
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </div>
        </el-col>

        <!-- 右侧信息栏 -->
        <el-col :span="5">
          <div class="side-content">
            <!-- 评价统计 -->
            <el-card class="side-card" shadow="hover">
              <div class="review-stats">
                <span class="stats-number">{{ form.recentreviews }}</span>
                <p class="stats-label">{{ form.acclaim }}</p>
              </div>
            </el-card>

            <!-- 在线人数 -->
            <el-card class="side-card" shadow="hover">
              <div class="online-stats" v-loading="playtrnering">
                <span class="stats-number">{{ playercount }}</span>
                <p class="stats-label">当前在线人数</p>
                <el-button type="primary" @click="getplayer(form.gameid)">刷新人数</el-button>
              </div>
            </el-card>

            <!-- 发行信息 -->
            <el-card class="side-card" shadow="hover">
              <div class="publish-info">
                <p><i class="el-icon-date"></i> {{ form.releasedate }} 发行</p>
                <p><i class="el-icon-office-building"></i> 开发商：{{ form.developers }}</p>
                <p><i class="el-icon-office-building"></i> 发行商：{{ form.publisher }}</p>
              </div>
            </el-card>

            <!-- 游戏标签 -->
            <el-card class="side-card" shadow="hover">
              <div class="game-tags">
                <el-tag v-for="item in tab" 
                        :key="item" 
                        size="small"
                        class="tag-item">{{ item }}</el-tag>
              </div>
            </el-card>

            <!-- 支持语言 -->
            <el-card class="side-card" shadow="hover">
              <div class="language-tags">
                <el-tag v-for="item in language" 
                        :key="item" 
                        size="small"
                        type="info"
                        class="tag-item">{{ item }}</el-tag>
              </div>
            </el-card>

            <!-- 配置需求 -->
            <el-card class="side-card" shadow="hover">
              <div class="config-requirements" v-html="form.configurationRequirements"></div>
            </el-card>

            <!-- 游戏官网 -->
            <el-card class="side-card" shadow="hover">
              <el-button type="primary" 
                        class="website-btn" 
                        @click="changelink(form.gameurc)">
                <i class="el-icon-link"></i> 访问游戏官网
              </el-button>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>
<script>

// import PeopleNumber from "@/pages/Charts/PeopleNumber";
// import HotSpecialty from "@/pages/Charts/HotSpecialty";
// import Swiperlist from "@/components/Swiperlist/index";
import axios from "axios";
import Skeleton from '@/components/Skeleton/index.vue'

export default {
  data() {
    return {
      loading: true,
      playtrnering:false,
      user: "", // 用户名
      fit: "", // 适配
      formInline: { // 表单初始数据
        user: "",
        region: "",
      },
      url: "static/img/htt/1.jpeg", // 默认图片URL
      value5: 4, // 评分值
      form: {}, // 游戏详情表单
      yearMajor: {}, // 年份与专业
      input: "", // 输入框内容
      comments: [], // 评论列表
      tab: [], // 标签列表
      language: [], // 语言列表
      playercount: 0, // 玩家人数
      isExpanded: false,
      hasOverflow: false,
      maxHeight: 300, // 收起时的最大高度
    };
  },
  methods: {
    // 跳转链接
    changelink(event) {
      console.log("<UNK>", event);
      console.log("http://" + event)
      // 使用新窗口打开链接
      window.open(event);
    },
    async fetchOnlinePlayers(game) {
      try {
        const res = await axios.get(
            `https://api.steampowered.com/ISteamUserStats/GetNumberOfCurrentPlayers/v1/?appid=${game}`
        );
        console.log(res)
        // this.playercount = res.data.response.player_count;
      } catch (error) {
        console.error('获取在线人数失败:', error);
      }
    },
    // 购买游戏
    shop(id) {
      axios.get(this.$globle.allurl + "shopgame", {
        params: {
          userid: JSON.parse(localStorage.getItem("user") || "{}").id, // 从localStorage中获取用户ID
          gameid: id // 游戏ID
        }
      }).then(res => {
        // 根据返回结果显示不同的提示信息
        switch (res.data) {
          case 1:
            this.$alert('游戏已购买', '购买失败', {
              confirmButtonText: '确定',
            });
            break;
          case 2:
            this.$alert('账户余额不足', '购买失败', {
              confirmButtonText: '确定',
            });
            break;
          case 3:
            this.$alert('游戏愉快', '购买成功', {
              confirmButtonText: '确定',
            });
            break;
        }
      });
    },

    // 加载游戏详情
    async load1() {
      const param = this.$route.query.id; // 从路由参数中获取游戏ID
      this.loading = true;
      try {
        const response = await axios.get(this.$globle.allurl + "getonegame/" + param);
        const game = response.data.game;

        // 设置游戏详情数据
        this.form = game;
        this.form.imgsrc = `https://media.st.dl.eccdnx.com/steam/apps/${game.gameid}/header.jpg`;

        // 处理游戏链接
        const gameurl = game.gameurc.split("?u=https%3A%2F%2F");
        this.form.gameurc = gameurl[1] ? gameurl[1] : game.gameurc;
        console.log(this.form.gameurc)

        // 处理语言列表
        this.language = game.lang.split("\n");

        // 处理详细图片和视频
        this.form.detialimg = game.pic.split("\n").map(img => `https://media.st.dl.eccdnx.com/steam/apps/${game.gameid}/${img}`);
        this.form.video1 = game.video.split("\n");
        this.form.videosrc = `https://media.st.dl.eccdnx.com/steam/apps/${game.gameid}/movie480_vp9.webm`;

        // 设置价格
        if (game.price === 0) {
          this.form.price = "免费开玩";
        }

        // 设置页面标题
        document.title = game.name;

        // 设置评论和标签
        this.comments = game.comments.split("\n");
        this.tab = game.tab.split("\n");

        // 计算评分
        this.value5 = game.goodrating / 20;
        this.getplayer( this.form.gameid)
        // 设置玩家数量
      } catch (error) {
        console.error("加载游戏详情时出错：", error);
      } finally {
        this.loading = false;
      }
    },

    // 获取玩家数量
    async getplayer(gameid) {
      try {
        this.playtrnering=true;
        const res = await axios.get(this.$globle.allurl + "getplayer/" + gameid);
        this.playercount = res.data.response.player_count;
        this.playtrnering = false;
      } catch (error) {
        console.error("获取玩家数量时出错：", error);
      }
    },

    checkOverflow() {
      this.$nextTick(() => {
        const content = this.$refs.detailContent;
        if (content) {
          this.hasOverflow = content.scrollHeight > this.maxHeight;
        }
      });
    },
    
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
  mounted() {
    // 组件挂载完成后加载游戏详情
    this.load1();
    this.checkOverflow();
    window.addEventListener('resize', this.checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow);
  },
  watch: {
    'form.concerning': {
      handler() {
        this.$nextTick(() => {
          this.checkOverflow();
        });
      },
      immediate: true
    }
  },
  components: {
    // PeopleNumber,
    // HotSpecialty,
    // Swiperlist,
    Skeleton,
  },
};
</script>
<style scoped>
.game-detail-container {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

.game-header {
  text-align: center;
  margin-bottom: 30px;
}

.game-title {
  font-size: 36px;
  color: #303133;
  margin-bottom: 15px;
}

.game-meta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.review-count {
  color: #909399;
  font-size: 16px;
}

.game-carousel {
  margin-bottom: 30px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.main-content {
  max-width: 1400px;
  margin: 0 auto;
}

.content-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.game-info-card {
  margin-bottom: 20px;
}

.game-description {
  padding: 20px;
}

.game-description h2 {
  margin-bottom: 15px;
  color: #303133;
}

.game-description p {
  color: #606266;
  line-height: 1.6;
  margin-bottom: 20px;
}

.game-tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.game-cover {
  padding: 20px;
}

.cover-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.card-header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.card-header i {
  margin-right: 8px;
  color: #1890ff;
}

.video-player {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.detail-content {
  position: relative;
}

.detail-text {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.detail-text-collapsed {
  max-height: 300px;
}

.detail-expand {
  text-align: center;
  padding: 10px 0;
  background: linear-gradient(to bottom, transparent, #fff);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.expand-btn {
  color: #1890ff;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.expand-btn:hover {
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.expand-btn i {
  margin-left: 5px;
  transition: transform 0.3s;
}

.expand-btn:hover i {
  transform: translateY(2px);
}

/* 确保内容区域有足够的内边距 */
.detail-text > div {
  padding: 20px;
}

.steam-widget {
  margin: 20px 0;
}

.steam-iframe {
  width: 100%;
  height: 190px;
  border-radius: 8px;
}

.review-list {
  margin-top: 20px;
  padding: 10px;
}

.review-item {
  margin-bottom: 20px;
  transition: all 0.3s ease;
  border: none;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.review-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.review-content {
  display: flex;
  gap: 20px;
  padding: 15px;
}

.review-avatar {
  flex-shrink: 0;
}

.review-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.reviewer-name {
  font-weight: bold;
  color: #303133;
  font-size: 16px;
}

.review-content-text {
  color: #606266;
  line-height: 1.6;
  margin: 0;
  font-size: 14px;
}

.review-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
  border-top: 1px solid #f0f0f0;
}

.review-time {
  color: #909399;
  font-size: 12px;
}

.like-btn {
  color: #909399;
  padding: 0;
  font-size: 12px;
  transition: all 0.3s;
}

.like-btn:hover {
  color: #ff9900;
}

.like-btn i {
  margin-right: 4px;
  font-size: 14px;
}

/* 自定义滚动条样式 */
.review-list::-webkit-scrollbar {
  width: 6px;
}

.review-list::-webkit-scrollbar-thumb {
  background-color: #909399;
  border-radius: 3px;
}

.review-list::-webkit-scrollbar-track {
  background-color: #f5f7fa;
  border-radius: 3px;
}

/* 添加响应式布局 */
@media screen and (max-width: 768px) {
  .review-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .review-header {
    flex-direction: column;
    gap: 8px;
  }
  
  .review-footer {
    flex-direction: column;
    gap: 8px;
  }
}

.side-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.side-card {
  margin-bottom: 0;
}

.review-stats, .online-stats {
  text-align: center;
  padding: 20px;
}

.stats-number {
  font-size: 36px;
  font-weight: bold;
  color: #1890ff;
  display: block;
}

.stats-label {
  margin: 10px 0 0;
  color: #909399;
}

.publish-info {
  padding: 20px;
}

.publish-info p {
  margin: 10px 0;
  color: #606266;
}

.publish-info i {
  margin-right: 8px;
  color: #1890ff;
}

.tag-item {
  margin: 5px;
}

.config-requirements {
  padding: 20px;
  font-size: 12px;
  line-height: 1.6;
  color: #606266;
}

.website-btn {
  width: 100%;
  padding: 12px;
}

.website-btn i {
  margin-right: 8px;
}
</style>
